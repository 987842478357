import { useSearchParams } from 'react-router-dom';
import { useState, createContext, useContext, useMemo } from 'react';

interface ProductContextProps {
  searchTerm: string;
  categoryList: CategoryList[];
  setCategoryList: (value: CategoryList[]) => void;
  cartList: any[];
  setCartList: (value: any) => void;
}

const ProductContext = createContext({} as ProductContextProps);

export default function ProductProvider({ children }: { children: React.ReactNode }) {
  const [searchTerm] = useSearchParams();
  const [categoryList, setCategoryList] = useState<CategoryList[]>([]);
  const [cartList, setCartList] = useState([]);

  const contextValue = useMemo(
    () => ({
      searchTerm: searchTerm.get('q') || '',
      categoryList,
      setCategoryList,
      cartList,
      setCartList
    }),
    [cartList, categoryList, searchTerm]
  );

  return <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>;
}

export function useProduct() {
  const context = useContext(ProductContext);
  return context;
}
