import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircleChevronLeft, CircleChevronRight } from 'lucide-react';
import { Button } from '~/components/ui/button';
import { useModal } from '~/context/modal-context';
import { Carousel, CarouselContent, CarouselItem, CarouselApi } from '~/components/ui/carousel';
import { useVibrate } from '~/hooks/use-vibrate';
import { formatCurrencyBRL } from '~/utils/format';
import { Http } from '~/helpers/api';

import Autoplay from 'embla-carousel-autoplay';
import ClassNames from 'embla-carousel-class-names';

export default function ProductPromotionList(): JSX.Element | null {
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>(null);
  const [productPromotionList, setProductPromotionList] = useState<Product[]>([]);
  const [searchParams] = useSearchParams();

  const { openModal } = useModal();
  const { vibrate } = useVibrate();

  useEffect(() => {
    (async () => {
      const data = await Http.get<{ results: Product[]; total: number }>('/produtos/promocao', {
        responseErrorContext: {
          message: 'Não foi possível carregar os produtos em promoção.'
        }
      });

      if (data) {
        setProductPromotionList(data.results);
      }
    })();
  }, []);

  if (!productPromotionList.length || searchParams.has('q')) return null;

  return (
    <section className='px-3 aria-[hidden=true]:hidden'>
      <div className='flex justify-between items-center bg-slate-200/70 rounded-md px-3 py-2'>
        <div>
          <h3 className='font-semibold text-base md:text-lg'>EM DESTAQUE</h3>
        </div>
        <div className='flex gap-1'>
          <Button
            variant='ghost'
            title='Item anterior'
            aria-label='Item anterior'
            size='icon'
            className='active:scale-90 transition-all bg-transparent duration-300 ease-in-out'
            onClick={() => {
              if (carouselApi) {
                vibrate(10);
                carouselApi?.scrollPrev();
              }
            }}
          >
            <CircleChevronLeft size={30} />
          </Button>
          <Button
            variant='ghost'
            title='Próximo item'
            aria-label='Próximo item'
            size='icon'
            className='active:scale-90 transition-all bg-transparent duration-300 ease-in-out'
            onClick={() => {
              if (carouselApi) {
                vibrate(10);
                carouselApi?.scrollNext();
              }
            }}
          >
            <CircleChevronRight size={30} />
          </Button>
        </div>
      </div>

      <Carousel
        className='w-full'
        setApi={setCarouselApi}
        opts={{ align: 'start', dragFree: true }}
        plugins={[Autoplay({ delay: 3000 }), ClassNames()]}
      >
        <CarouselContent className='mt-3 gap-4'>
          {productPromotionList?.map((product) => (
            <CarouselItem
              key={product.cod}
              className='w-[200px] cursor-grab active:cursor-grabbing shadow-sm'
              onClick={() => openModal(product)}
            >
              <div className='flex flex-col justify-between items-center gap-4 border p-2 h-full rounded-lg'>
                <div className='flex flex-col items-center gap-3 w-full h-full'>
                  <img
                    src={product.imagem === 'N' || !product.imagem ? '/images/no-image.svg' : product.imagem}
                    className='w-full object-scale-down h-40 rounded-lg'
                    draggable={false}
                    loading='lazy'
                    title={product.titulo}
                    alt={product.titulo}
                  />

                  <h3 className='font-bold text-sm text-center line-clamp-2'>{product.titulo}</h3>
                </div>
                <div className='w-full'>
                  <div className='w-full'>
                    <span className='font-bold mr-2'>De:</span>
                    <span className='text-red-600 line-through font-extrabold'>
                      {formatCurrencyBRL(Number(product.valor_unitario) || 0)}
                    </span>
                  </div>
                  <div className='w-full'>
                    <span className='font-bold mr-2'>Por:</span>
                    <span className='font-extrabold text-green-600'>
                      {formatCurrencyBRL(Number(product.preco_promo) || 0)}
                    </span>
                  </div>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </section>
  );
}
