import type { SubproductSectionListProps } from './types';

import { twMerge } from 'tailwind-merge';
import { getDescriptionCategory } from '~/utils/message';

import SubproductItemRadio from './subproduct-item-radio';
import SubproductItemCount from './subproduct-item-count';

export default function ProductList({ items, className = '' }: SubproductSectionListProps): JSX.Element {
  const getInputType = (min: number, max: number): string => {
    return Number(min) === 1 && Number(max) === 1 ? 'radio' : 'text';
  };

  return (
    <div className={twMerge('flex flex-col gap-3 select-none', className)}>
      {items?.map(({ categoria_sabor_id, categoria_sabor, qtd_max, qtd_min, sabores }) => (
        <div key={categoria_sabor_id}>
          <div className={twMerge('sticky -top-1 z-30 border bg-slate-100 shadow-sm p-2 rounded-lg')}>
            <h4 className='font-bold uppercase'>{categoria_sabor}</h4>
            <div className='flex justify-between items-center'>
              <p className="text-sm font-medium text-slate-600 before:content-['('] after:content-[')']">
                {getDescriptionCategory({
                  max: Number(qtd_max),
                  min: Number(qtd_min)
                })}
              </p>
            </div>
          </div>
          <section className='flex flex-col py-3 gap-2'>
            {sabores?.map(({ cod, sabor, valor, adicional }: Taste) =>
              getInputType(qtd_min, qtd_max) === 'radio' ? (
                <SubproductItemRadio
                  key={cod}
                  categoryID={categoria_sabor_id}
                  category={categoria_sabor}
                  subProduct={{ adicional, cod, sabor, valor }}
                />
              ) : (
                <SubproductItemCount
                  key={cod}
                  categoryID={categoria_sabor_id}
                  category={categoria_sabor}
                  subProduct={{ adicional, cod, sabor, valor }}
                  validationsProp={{ min: qtd_min, max: qtd_max }}
                />
              )
            )}
          </section>
        </div>
      ))}
    </div>
  );
}
