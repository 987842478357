import { useStore } from '~/context/store-context';

export default function Header(): JSX.Element {
  const shopInfo = useStore();

  return (
    <header className='w-full'>
      <div
        className='h-24 md:h-28 lg:h-40 lg:rounded-b-xl bg-conver bg-blue-500 shadow-sm relative'
        style={{
          backgroundImage: `url(${shopInfo?.capa})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          objectFit: 'cover'
        }}
      ></div>

      <div className='flex items-center relative px-3 mt-2 lg:mt-5 lg:px-0'>
        <div className='lg:absolute lg:left-3 lg:bottom-0 border border-slate-400/20 rounded-lg shadow-sm'>
          <img
            className='w-[6rem] h-[4.3rem] md:w-20 md:h-20 lg:w-32 lg:h-32 rounded-lg object-scale-down object-center lg:object-cover'
            src={shopInfo?.logo as string}
            draggable='false'
            loading='lazy'
            alt='Logo'
          />
        </div>

        <div className='flex justify-center flex-col gap-1 ml-3 lg:w-full lg:ml-40'>
          <div className='flex justify-between items-center gap-3'>
            <div className='flex items-center gap-2'>
              <h1 className='text-lg text-gray-700/95 md:text-2xl lg:text-3xl font-extrabold tracking-tight'>
                {shopInfo?.fantasia}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
