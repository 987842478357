import { twMerge } from 'tailwind-merge';
import { useModal } from '~/context/modal-context';
import Price from './price';

interface ProductCardProps {
  as?: React.ElementType;
  className?: string;
  product: Product;
}

export default function ProductCard({
  as: Element = 'li',
  className = '',
  product
}: ProductCardProps): JSX.Element {
  const { openModal } = useModal();

  return (
    <Element
      className={twMerge('pointer border select-none border-slate-200/70 p-2 lg:p-3 rounded-lg', className)}
      onClick={() => openModal(product)}
    >
      <div className='card_product'>
        <div className='card_product__content'>
          <div className='mb-2'>
            <h4 className='font-bold line-clamp-2 lg:line-clamp-none'>{product?.titulo}</h4>
            <p className='card_product__content__description'>
              {!product.descricao || product.descricao === 'N' ? '...' : product.descricao}
            </p>
          </div>

          <Price
            defaultPrice={product.valor_unitario}
            discountPrice={product.promocao === 'S' ? product.preco_promo : null}
          />
        </div>
        <div className='w-40 h-28 lg:h-32'>
          <img
            src={product.imagem === 'N' || !product.imagem ? '/images/no-image.svg' : product.imagem}
            className='object-scale-down object-center w-full h-full rounded-lg'
            title={product?.titulo}
            alt={product?.titulo}
            loading='lazy'
            draggable={false}
          />
        </div>
      </div>
    </Element>
  );
}
