import { createContext, useContext, useEffect, useState } from 'react';
import { Http } from '~/helpers/api';

const StoreContext = createContext<null | StoreData>(null);

export function StoreProvider({ children }: { children: Readonly<React.ReactNode> }) {
  const [store, setStore] = useState<StoreData | null>(null);

  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const data = await Http.get<StoreData>('/loja', {
        signal: controller.signal,
        responseErrorContext: {
          message: 'Não foi possível carregar as informações da loja.'
        }
      });

      if (data) {
        setStore(data);
      }
    })();

    return () => controller.abort();
  }, []);

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export function useStore() {
  const context = useContext(StoreContext);
  return context;
}
