export function getDescriptionCategory({ min = 0, max = 0 }: { max: number; min: number }): string {
  if ((min === 0 && max === 0) || (min === 1 && max === 1)) {
    return 'Escolha 1 das opções'
  }

  if (min > 1 && max > 1 && max === min) {
    return `Escolha ${max} opções`
  }

  if (min >= 1 && max > min) {
    return `Escolha entre ${min} a ${max} opções`
  }

  return ''
}
