import { createBrowserRouter, Navigate } from 'react-router-dom';

import RootLayout from './layouts/root-layout';
import Loader from '~/shared/loader';
import MenuPage from '~/pages/menu';

export const browserRouter = createBrowserRouter(
  [
    {
      path: '/',
      Component: RootLayout,
      children: [
        {
          index: true,
          element: <MenuPage />,
          HydrateFallback: Loader,
          errorElement: <Navigate to='/' replace />
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to='/' replace />
    }
  ],
  {
    future: {
      v7_partialHydration: true
    },
    basename: import.meta.env.BASE_URL
  }
);
