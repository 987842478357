import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import { Input } from '~/components/ui/input';

export default function SearchInput(): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [typedPlaceholder, setTypedPlaceholder] = useState<string>('');
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const placeholderList = useMemo(() => ['Pizza', 'Hamburguer', 'Sorvete', 'Açaí', 'Coxinha', 'Pastel'], []);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm.length > 0) {
      navigate({ search: `?q=${searchTerm}` });
    } else {
      navigate({ search: '' });
    }
  }, [navigate, searchTerm]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!searchTerm.length) {
      timeout = setTimeout(() => {
        const currentPlaceholder = placeholderList[placeholderIndex];

        if (isTyping) {
          if (typedPlaceholder.length < currentPlaceholder.length) {
            setTypedPlaceholder((prev) => prev + currentPlaceholder[prev.length]);
          } else {
            setIsTyping(false);
          }
        } else {
          if (typedPlaceholder.length > 0) {
            setTypedPlaceholder((prev) => prev.slice(0, -1));
          } else {
            setIsTyping(true);
            setPlaceholderIndex((prev) => (prev + 1) % placeholderList.length);
          }
        }
      }, 160);
    }

    return () => clearTimeout(timeout);
  }, [placeholderIndex, typedPlaceholder, isTyping, placeholderList, searchTerm.length]);

  return (
    <div className='px-3'>
      <Input
        type='search'
        value={searchTerm}
        autoComplete='off'
        aria-autocomplete='both'
        spellCheck='false'
        placeholder={`Buscar por "${typedPlaceholder}"`}
        className='w-full h-14 font-bold'
        aria-label='Buscar no cardápio'
        startIcon={Search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
      />
    </div>
  );
}
