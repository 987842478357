import { BadgePercent } from 'lucide-react'
import { twMerge } from 'tailwind-merge'
import { formatCurrencyBRL } from '~/utils/format'

interface PriceProductProps {
  defaultPrice: number
  discountPrice: number | null
}

export default function Price({ defaultPrice, discountPrice = null }: PriceProductProps): JSX.Element {
  return (
    <div className='py-1 flex gap-2'>
      <div className={twMerge('font-extrabold', discountPrice && 'text-red-600 line-through')}>
        {formatCurrencyBRL(Number(defaultPrice))}
      </div>
      {discountPrice && (
        <>
          <BadgePercent />
          <div className='font-extrabold text-green-600'>{formatCurrencyBRL(Number(discountPrice))}</div>
        </>
      )}
    </div>
  )
}
