import { newtonsCradle } from 'ldrs'

export default function Loader(): JSX.Element {
  newtonsCradle.register()
  return (
    <div className='h-screen w-full grid place-items-center overflow-hidden'>
      <l-newtons-cradle size='78' speed='1.4' color='black'></l-newtons-cradle>
    </div>
  )
}
