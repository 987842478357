import { useState, useEffect } from 'react';
import { SearchX } from 'lucide-react';
import { useProduct } from '~/context/product-context';
import { Http } from '~/helpers/api';
import { replaceWhiteSpace } from '~/utils/format';

import ProductCard from './product-card';

export default function ProductList(): JSX.Element {
  const [productList, setProductList] = useState<ProductList[]>([]);

  const { searchTerm, setCategoryList } = useProduct();

  useEffect(() => {
    (async function () {
      const data = await Http.get<{ results: ProductList[]; total: number }>('/produtos', {
        params: {
          ...(searchTerm && { q: searchTerm })
        },
        responseErrorContext: {
          message: 'Não foi possível carregar os produtos da loja.'
        }
      });

      if (data) {
        setProductList(data?.results);
        setCategoryList(
          data.results?.map((product: ProductList) => ({
            id: product.id_categoria,
            nome: product.categoria
          }))
        );
      }
    })();
  }, [searchTerm, setCategoryList]);

  return (
    <div className='flex flex-col space-y-3'>
      {productList.length > 0 &&
        productList?.map((product) => (
          <section key={product?.id_categoria} id={replaceWhiteSpace(product.categoria)} className='px-3'>
            <div className='sticky -top-1 bg-slate-200 rounded-md px-2 lg:px-3 py-3'>
              <h3 className='font-semibold uppercase text-base md:text-lg'>{product?.categoria}</h3>
            </div>

            <div className='py-4'>
              <ul className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 lg:gap-4'>
                {product?.produtos.map((product) => <ProductCard key={product?.cod} product={product} />)}
              </ul>
            </div>
          </section>
        ))}

      {productList.length === 0 && searchTerm.length > 0 && (
        <div className='flex flex-col items-center justify-center gap-3 text-neutral-700 mt-[10%]'>
          <SearchX size={30} />
          <h3 className='text-center text-lg  font-medium'>Sem resultados para "{searchTerm}"</h3>
        </div>
      )}
    </div>
  );
}
