import { useState, createContext, useContext, useMemo, useCallback } from 'react';

interface ModalContextData {
  openModal: (product: Product) => void;
  closeModal: () => void;
  product: Product | null;
  isOpen: boolean;
}

const ModalContext = createContext({} as ModalContextData);

export default function ModalProvider({ children }: { children: React.ReactNode }) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [product, setProduct] = useState<Product | null>(null);

  const openModal = useCallback((product: Product) => {
    setProduct(product);
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setProduct(null);
    setShowModal(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      openModal,
      closeModal,
      product,
      isOpen: showModal
    }),
    [openModal, closeModal, product, showModal]
  );

  return (
    <ModalContext.Provider value={contextValue as unknown as ModalContextData}>
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  return context;
}
