import { Outlet } from 'react-router-dom';

import ModalProvider from '~/context/modal-context';
import ProductProvider from '~/context/product-context';

export default function RootLayout(): JSX.Element {
  return (
    <ProductProvider>
      <div className='wrapper__app'>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </div>
    </ProductProvider>
  );
}
