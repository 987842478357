import type { ExternalToast } from 'sonner'

import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { toast } from 'sonner'

interface HttpOptions extends AxiosRequestConfig {
  responseErrorContext?: {
    message: { statusCode: number; message: string } | string
    data?: ExternalToast
  }
  responseSuccessContext?: {
    message: string
    data?: ExternalToast
  }
}

export class Http {
  private static api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  public static async get<T>(url: string, params?: HttpOptions) {
    try {
      const res = await this.api.get<T>(url, params)

      if (params && 'responseSuccessContext' in params) {
        toast.success(params.responseSuccessContext?.message, {
          ...(params.responseSuccessContext?.data || {}),
        })
      }

      return res.data
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (
          params &&
          'responseErrorContext' in params &&
          typeof params.responseErrorContext?.message === 'object'
        ) {
          const { message, statusCode } = params.responseErrorContext.message
          if (err.response?.status === statusCode) {
            toast.error(message, {
              ...(params.responseErrorContext?.data || {}),
            })
          }
        } else if (
          params &&
          'responseErrorContext' in params &&
          typeof params.responseErrorContext!.message === 'string'
        ) {
          toast.error(params.responseErrorContext!.message, {
            ...(params.responseErrorContext?.data || {}),
          })
        }
      }
    }
  }

  public static async post<T>(url: string, data: any, params?: HttpOptions) {
    try {
      const res = await this.api.post<T>(url, data, params)

      if (params && 'responseSuccessContext' in params) {
        toast.success(params.responseSuccessContext?.message, {
          ...params.responseSuccessContext?.data,
        })
      }

      return res.data
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (params && 'responseErrorContext' in params) {
          if ('responseErrorContext' in params && typeof params.responseErrorContext?.message === 'object') {
            const { message, statusCode } = params.responseErrorContext.message
            if (err.response?.status === statusCode) {
              toast.error(message, {
                ...(params.responseErrorContext?.data || {}),
              })
            }
          } else if (
            'responseErrorContext' in params &&
            typeof params.responseErrorContext?.message === 'string'
          ) {
            toast.error(params.responseErrorContext.message, {
              ...(params.responseErrorContext?.data || {}),
            })
          }
        }
      }

      return null
    }
  }

  public static async put<T>(url: string, data: any, params?: HttpOptions) {
    try {
      const res = await this.api.put<T>(url, data, params)

      if (params && 'responseSuccessContext' in params) {
        toast.success(params.responseSuccessContext?.message, {
          ...(params.responseSuccessContext?.data || {}),
        })
      }

      return res.data
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (params && 'responseErrorContext' in params) {
          if ('responseErrorContext' in params && typeof params.responseErrorContext?.message === 'object') {
            const { message, statusCode } = params.responseErrorContext.message
            if (err.response?.status === statusCode) {
              toast.error(message, {
                ...(params.responseErrorContext.data || {}),
              })
            }
          } else if (
            'responseErrorContext' in params &&
            typeof params.responseErrorContext?.message === 'string'
          ) {
            toast.error(params.responseErrorContext.message, {
              ...(params.responseErrorContext.data || {}),
            })
          }
        }
      }

      return null
    }
  }
}
