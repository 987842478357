export function formatCurrencyBRL(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

export function replaceWhiteSpace(value: string, pattern: string = '_'): string {
  if (!value) return ''
  return value.toLowerCase().replace(/\s/g, pattern)
}
