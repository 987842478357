import { RouterProvider } from 'react-router-dom'
import { browserRouter } from '~/routes'
import { StoreProvider } from '~/context/store-context'
import { Toaster as ToasterSonner } from '~/components/ui/sonner'
import { Toaster as Toaster } from '~/components/ui/toaster'

export default function App() {
  return (
    <StoreProvider>
      <RouterProvider router={browserRouter} />
      <ToasterSonner />
      <Toaster />
    </StoreProvider>
  )
}
