import { twMerge } from 'tailwind-merge';
import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '~/components/ui/dialog';
import { useModal } from '~/context/modal-context';

import SubproductSectionList from './subproducts-section-list';

export default function ModalProductDetails(): JSX.Element {
  const { product, isOpen, closeModal } = useModal();

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseModal}>
      <DialogContent
        className={twMerge(
          'flex flex-col items-center justify-between outline-none h-full w-full',
          'sm:max-h-[80%] sm:max-w-[70%] lg:max-h-[75%] lg:max-w-[920px]'
        )}
      >
        <div className='h-full'>
          <DialogHeader
            className={twMerge(
              'absolute top-0 rounded-t-xl p-3 inset-x-0 border-b',
              'border-slate-300/60 shadow-sm bg-slate-200/30'
            )}
          >
            <DialogTitle className='font-bold text-center text-lg'>Detalhes do Item</DialogTitle>
          </DialogHeader>
          <DialogBody className='absolute inset-x-0 top-3 bottom-2 px-3'>
            <div className='flex flex-col gap-1 lg:flex-row lg:gap-2 h-full overflow-y-auto no-scrollbar'>
              <div className='lg:w-1/2'>
                <div className='h-full mb-2'>
                  <figure>
                    <img
                      src={
                        !product?.imagem || product?.imagem === 'N' ? '/images/no-image.svg' : product?.imagem
                      }
                      className='w-full h-52 md:h-56 lg:h-60 object-contain border border-neutral-100 rounded-lg'
                      width='100%'
                      title={product?.titulo}
                      loading='lazy'
                      alt={product?.titulo}
                    />
                  </figure>

                  <div className='mt-3 flex flex-col gap-2'>
                    <h6 className='font-bold text-center'>{product?.titulo}</h6>
                    <DialogDescription className='whitespace-break-spaces text-justify line-clamp-5'>
                      {product?.descricao}
                    </DialogDescription>
                  </div>
                </div>
              </div>

              <div
                className={twMerge(
                  'lg:w-1/2 lg:overflow-y-auto no-scrollbar h-full',
                  !product?.subprodutos && 'h-full'
                )}
              >
                <SubproductSectionList items={product?.subprodutos || []} />
              </div>
            </div>
          </DialogBody>
          <DialogFooter className='absolute bottom-0 inset-x-0 p-3 border-t border-slate-300/60 rounded-b-xl bg-slate-200/30'>
            <p className='text-center w-full font-semibold'>Cardápio apenas visualização</p>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
}
