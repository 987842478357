import { replaceWhiteSpace } from '~/utils/format'
import { useProduct } from '~/context/product-context'
import { Carousel, CarouselContent, CarouselItem } from '~/components/ui/carousel'

export default function CategoryList(): JSX.Element {
  const { categoryList } = useProduct()

  return (
    <section className='px-3'>
      <Carousel
        className='py-1'
        opts={{
          align: 'start',
          dragFree: true,
        }}>
        <CarouselContent className='gap-2'>
          {categoryList.map((category) => (
            <CarouselItem
              key={category.id}
              className='border p-2 rounded-lg cursor-grab active:cursor-grabbing'>
              <a href={`#${replaceWhiteSpace(category.nome)}`} className='lowercase font-semibold'>
                {category.nome}
              </a>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </section>
  )
}
