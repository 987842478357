import Header from '~/components/header';
import ProductList from '~/components/product-list';
import ProductPromotionList from '~/components/product-promotion-list';
import CategoryList from '~/components/category-list';
import SearchInput from '~/components/search-input';
import ModalProductDetails from '~/components/modal/ModalProductDetails';

export default function MenuPage(): JSX.Element {
  return (
    <>
      <Header />
      <main className='flex flex-col mt-3 gap-3'>
        <SearchInput />
        <CategoryList />
        <ProductPromotionList />
        <ProductList />
        <ModalProductDetails />
      </main>
    </>
  );
}
