import type { SubproductItemRadioProps } from './types';
import { formatCurrencyBRL } from '~/utils/format';

export default function SubproductItemRadio({
  subProduct = null,
  isScape = false
}: SubproductItemRadioProps): JSX.Element {
  return (
    <div className='flex justify-between items-center relative border border-neutral-100 rounded-lg h-14'>
      <div className='flex flex-col justify-center w-full h-full px-2 z-10'>
        <span className='font-medium text-base lowercase'>{isScape ? 'não quero' : subProduct?.sabor}</span>
        {subProduct && subProduct?.valor > 0 && (
          <span className='text-xs font-semibold text-green-600'>{formatCurrencyBRL(subProduct?.valor)}</span>
        )}
      </div>
    </div>
  );
}
