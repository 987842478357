import * as React from 'react'

import { cn } from '~/lib/shadcn.ts'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ElementType
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startIcon: StartIcon, ...props }, ref) => {
    return (
      <div className='relative'>
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            StartIcon && 'pl-12',
            className,
          )}
          ref={ref}
          {...props}
        />
        {StartIcon && (
          <div className='absolute inset-y-1 left-3 flex items-center pointer-events-none'>
            <StartIcon className='text-gray-700' />
          </div>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
